export function delegate(element, selector, eventType, handler) {
  element.addEventListener(eventType, function(event) {
    let target = event.target;

    while (!(!(target instanceof Element) || matches(target, selector))) {
      target = target.parentNode;
    }

    if (target instanceof Element && handler.call(target, event) === false) {
      event.preventDefault();
      event.stopPropagation();
    }
  });
}

export function fire(object, name, data) {
  object.dispatchEvent(
    new CustomEvent(name, {
      detail: data,
      bubbles: true,
      cancelable: true
    })
  );
}