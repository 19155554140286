import '../stylesheets/main.scss';
import '../images/favicon.ico';
import './setup';

// App
import './components/form';
import './components/offset';
import './components/range';

// It's important that 'chart' comes after 'form' as its
// ajax:success needs to happen after content is replaced.
import './components/chart';