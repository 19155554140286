document.addEventListener("DOMContentLoaded", function(event) {  
  initChart(document.querySelector('main'));
});

document.addEventListener('ajax:success', function(event) {
  let detail = event.detail,
      data = detail[0], status = detail[1], xhr = detail[2];
      
  initChart(document.querySelector('main'));  
});

function initChart(root) {
  for (let chart of root.querySelectorAll('.c-chart')) {
    new Chartist.Bar(
      chart,
      {
        labels: [          
            chart.getAttribute('label-1'),
            chart.getAttribute('label-2'),
            chart.getAttribute('label-3')
          ],
        series: [
          [
            chart.getAttribute('data-1'),
            chart.getAttribute('data-2'),
            chart.getAttribute('data-3')
          ]
        ]
      },
      {
        seriesBarDistance: 10,
        height: 300,
      },
      {}
    );
  }
}