import rangeSlider from 'rangeslider-pure/dist/range-slider.js';
import { delegate, fire } from '../tools/events'

document.addEventListener("DOMContentLoaded", function(event) {  
  setupRangeSliders(document.querySelector('main'));
});

document.addEventListener('ajax:success', function(event) {
  let detail = event.detail,
      data = detail[0], status = detail[1], xhr = detail[2];
      
  setupRangeSliders(document.querySelector('main'));  
});

function setupRangeSliders(root) {
  for (let range of root.querySelectorAll('.c-range')) {
    let input = range.querySelector('input'),
        output = range.querySelector('output'),
        prev;
    
    rangeSlider.create(input, {
      polyfill: true,
      rangeClass: 'c-range__control',
      disabledClass: 'c-range__control--disabled',
      fillClass: 'c-range__fill',
      bufferClass: 'c-range__buffer',
      handleClass: 'c-range__handle',
      onSlideStart: function (position, value) {
        prev = value;
      },
      onSlideEnd: function(position, value) {
        if (value !== prev) {
          fire(input, 'change');
        }
      }
    });
    
    output.value = input.value;
    
    input.addEventListener('input', function() {
      output.value = input.value;
    });
    
    const observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (mutation.attributeName === 'disabled') {
          input.rangeSlider.update();
        }
      });    
    });

    observer.observe(input, { attributes: true });
  }
}