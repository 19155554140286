function initMap() {
    // Map characteristics
    var options = {
        zoom:2,
        center: {lat: 0, lng: 0},
        styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                        ]
                    },
                    {
                        "elementType": "labels",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                        {
                            "color": "#616161"
                        }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                        ]
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                        {
                            "color": "#757575"
                        }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                        ]
                    },
                    {
                        "featureType": "road",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "color": "#ffffff"
                        }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [
                        {
                            "color": "#757575"
                        }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "color": "#dadada"
                        }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [
                        {
                            "color": "#616161"
                        }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "color": "#c9c9c9"
                        }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                        ]
                    }
                ]
    }

    // Tree icon: 'http://maps.google.com/mapfiles/kml/pal2/icon4.png'
    // New map
    //var map = new google.maps.Map(document.querySelector('.c-offset'), options);
    var map = new google.maps.Map(document.getElementById('map'), options);

    // Array of markers
    var markers = [
    {
        coords:{lat:-44.059769,lng:-72.624484},
        content:'<h4><img src="https://www.reforestemos.cl/wp-content/themes/patagonia/images/logo_es.png""></h4><h4>Reforestemos</h4>' +
        '<p>Location: Chilean Patagonia</p>' +
        '<p>Price: 6 USD</p>' +
        '<p>Pros: GPS Location of the Tree, Tree Planting Certificate</p>' +
        '<p><a href="https://www.reforestemos.cl/site_old/bosque.php?url=climax-community">Website</a></p>' 
    },
    {
        coords:{lat:46.289868,lng:25.290137},
        content:'<h4><img src="https://fundaforest.eu/assets/images/logo.png" style="width:36px;height:36px;"></h4><h4>Land Art Association</h4>' +
        '<p>Location: Romania</p>' +
        '<p>Price: 1 Euro per M2</p>' +
        '<p>Pros: Pros: They actually plant 3 trees in 1m2 for 1 Euro</p>' +
        '<p><a href="https://fundaforest.eu/en/">Website</a></p>' 
    },
    {
        coords:{lat:-5.990698,lng:-75.075222},
        content: '<h4><img src="https://i.imgur.com/XoruTKY.png" style="width:155px;height:36px;"></h4><h4>One Tree Planted</h4>' +
        '<p>Location: Peru</p>' +
        '<p>Price: 1 Dollar</p>' +
        '<p>Pros: Tree Planting Certificate</p>' +
        '<p><a href="https://onetreeplanted.org/products/amazon-rainforest-peru">Website</a></p>' 
    },
    {
        coords:{lat:-25.817636,lng:-52.354246},
        content: '<h4><img src="https://i.imgur.com/XoruTKY.png" style="width:155px;height:36px;"></h4><h4>One Tree Planted</h4>' +
        '<p>Location: Brazil</p>' +
        '<p>Price: 1 Dollar</p>' +
        '<p>Pros: Tree Planting Certificate</p>' +
        '<p><a href="https://onetreeplanted.org/collections/latin-america/products/brazil-forests">Website</a></p>' 
    },
    {
        coords:{lat:19.221244,lng:-72.658968},
        content: '<h4><img src="https://i.imgur.com/XoruTKY.png" style="width:155px;height:36px;"></h4><h4>One Tree Planted</h4>' +
        '<p>Location: Haiti</p>' +
        '<p>Price: 1 Dollar</p>' +
        '<p>Pros: Tree Planting Certificate</p>' +
        '<p><a href="https://onetreeplanted.org/collections/latin-america/products/haiti">Website</a></p>' 
    },
    {
        coords:{lat:16.117779,lng:-90.091446},
        content: '<h4><img src="https://i.imgur.com/XoruTKY.png" style="width:155px;height:36px;"></h4><h4>One Tree Planted</h4>' +
        '<p>Location: Guatemala</p>' +
        '<p>Price: 1 Dollar </p>' +
        '<p>Pros: Tree Planting Certificate</p>' +
        '<p><a href="https://onetreeplanted.org/collections/latin-america/products/guatemala-forests">Website</a></p>' 
    },
    {
        coords:{lat:43.538802,lng:-5.704748},
        content: '<h4><img src="https://bosquia.es/wp-content/uploads/2018/05/Bosquia_logo.svg" style="width:155px;height:36px;"></h4><h4>Bosquia</h4>' +
        '<p>Location: Spain</p>' +
        '<p>Price: 20 Euro</p>' +
        '<p>Pros: Tree Planting Certificate, GPS location of the tree!, tree tag with your name on it</p>' +
        '<p><a href="https://bosquia.es/producto/apadrinar-arbol/">Website</a></p>' 
    }
    ];

    var infoWindows = Array();

    // Loop through markers
    for (var i = 0; i < markers.length; i++) {
        // Add marker
        addMarker(markers[i]);
    }

    // Add Marker Function
    function addMarker(props) {
      var marker = new google.maps.Marker({
          position:props.coords,
          map:map,
          icon: 'http://maps.google.com/mapfiles/kml/pal2/icon4.png'
      });

      // Check content
      if (props.content) {        
          var infoWindow = new google.maps.InfoWindow({
              content:props.content
          });

          // Keep track of info windows
          infoWindows.push(infoWindow);

          marker.addListener('click', function() {
              // Close all previous info windows
              for (i = 0; i < infoWindows.length; i++) {
                  infoWindows[i].close();
              }

              infoWindow.open(map, marker);
          });
      }
    }
}

window.initMap = initMap;
