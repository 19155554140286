import { delegate, fire } from '../tools/events'

// For simplicity, we're just going to replace the contents
// of the 'main' element. So simply set 'data-remote'
// attribute to true, and optionaly 'data-live' if you want
// submits to happen on any value change.

// Capture remote submits and make them post via ajax.
document.addEventListener('submit', function(event) {
  const form = event.target;
  
  if (form.getAttribute('data-remote')) {
    event.preventDefault();
    remoteForm(form);
  }
});
  
// Handle change events on fields for 'auto' submits.
document.addEventListener('change', function(event) {
  const field = event.target,
        form = field.closest('form');
  
  if (form.getAttribute('data-auto')) {
    if (field.matches('input, select')) {
      remoteForm(form);
    }
  }
});

function remoteForm(form) {
  let data = new FormData(form),
      request = new XMLHttpRequest();

  fire(form, 'ajax:before');
  
  // Handle the response my just replacing 'main' element.
  request.onreadystatechange = function() {
    if (request.readyState == request.DONE && request.status == 200) {      
      fire(form, 'ajax:success', [request.responseText, 200, request]);
    }
  };
  
  // Post the form.
  request.open("POST", form.getAttribute('action'));
  request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  request.send(data);
}

// Disable all elements to stop submit race conditions.
document.addEventListener('ajax:before', function(event) {
  for (let field of event.target.querySelectorAll('input, select, button')) {
    field.disabled = true;
  }
});

// Replace 'main' element on success.
document.addEventListener('ajax:success', function(event) {
  let detail = event.detail,
      data = detail[0], status = detail[1], xhr = detail[2];
      
  document.querySelector('main').innerHTML = data;
});